import { Format, ReportKind } from '@lib/constants/report-formats.constant'
import {
  ReportScheduleRequestInterface,
  ScheduleItem,
} from '@lib/interfaces/schedule-report.interface'

class ReportScheduleModel {
  id?: string

  reportKind: ReportKind

  scheduleItemsList: Array<ScheduleItem>

  emailsList: Array<string>

  reportFormatsList: Array<Format>

  constructor(attrs: ReportScheduleRequestInterface) {
    this.id = attrs.id
    this.reportKind = attrs.reportKind
    this.scheduleItemsList = attrs.scheduleItemsList
    this.emailsList = attrs.emailsList
    this.reportFormatsList = attrs.reportFormatsList
  }

  getId(): string | undefined {
    return this.id
  }

  getReportKind(): ReportKind {
    return this.reportKind
  }

  getScheduleItemsList(): Array<ScheduleItem> {
    return this.scheduleItemsList
  }

  getEmailList(): Array<string> {
    return this.emailsList
  }

  getReportFormatsList(): Array<Format> {
    return this.reportFormatsList
  }
}

export default ReportScheduleModel
