"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EBS = void 0;
const aws_asset_1 = require("../../../assets/aws/aws-asset");
class EBS extends aws_asset_1.AWSAsset {
    #state;
    #type;
    #encryptionDetails;
    #performanceCharacteristics;
    #attachedInstanceIds;
    #retentionPolicy;
    #ccAssetId;
    #nonRecognizedVolumeType;
    #awsAz;
    #size;
    #awsCreatedAt;
    #hasReplicatedBackups;
    constructor(parameters) {
        const { state, type, encryptionDetails, performanceCharacteristics, attachedInstanceIds, retentionPolicy, nonRecognizedVolumeType, awsAz, ccAssetId, size, awsCreatedAt, hasReplicatedBackups, ...awsAssetParameters } = parameters;
        super(awsAssetParameters);
        this.#state = state;
        this.#type = type;
        this.#encryptionDetails =
            encryptionDetails && Object.freeze({ ...encryptionDetails });
        this.#performanceCharacteristics =
            performanceCharacteristics &&
                Object.freeze({
                    ...performanceCharacteristics,
                });
        this.#attachedInstanceIds = Object.freeze([...attachedInstanceIds]);
        this.#retentionPolicy = Object.freeze([...retentionPolicy]);
        this.#ccAssetId = ccAssetId;
        this.#nonRecognizedVolumeType = nonRecognizedVolumeType;
        this.#awsAz = awsAz;
        this.#size = size;
        this.#awsCreatedAt = awsCreatedAt;
        this.#hasReplicatedBackups = hasReplicatedBackups;
    }
    get attachedInstanceIds() {
        return this.#attachedInstanceIds;
    }
    get retentionPolicy() {
        return this.#retentionPolicy;
    }
    get awsAz() {
        return this.#awsAz;
    }
    get size() {
        return this.#size;
    }
    get awsCreatedAt() {
        return this.#awsCreatedAt;
    }
    get state() {
        return this.#state;
    }
    get type() {
        return this.#type;
    }
    get encryptionDetails() {
        if (!this.#encryptionDetails) {
            return;
        }
        return { ...this.#encryptionDetails };
    }
    get performanceCharacteristics() {
        if (!this.#performanceCharacteristics) {
            return;
        }
        return { ...this.#performanceCharacteristics };
    }
    get ccAssetId() {
        return this.#ccAssetId;
    }
    get nonRecognizedVolumeType() {
        return this.#nonRecognizedVolumeType;
    }
    get encrypted() {
        if (!this.#encryptionDetails) {
            return false;
        }
        return this.#encryptionDetails.encrypted;
    }
    get encryptedWithCmk() {
        if (!this.encrypted) {
            return;
        }
        return this.#encryptionDetails?.encryptedWithCmk;
    }
    get isUnencrypted() {
        return !this.encrypted;
    }
    get encryptedWithoutCmk() {
        return this.encryptedWithCmk !== undefined
            ? !this.encryptedWithCmk
            : undefined;
    }
    get isNotAttached() {
        return this.#attachedInstanceIds.length === 0;
    }
    get canBeDirectlyScanned() {
        return true;
    }
    get hasReplicatedBackups() {
        return this.#hasReplicatedBackups;
    }
}
exports.EBS = EBS;
