import { createTheme } from '@mui/material'

const black = '#130638'
const whiteBlue = '#33B6F2'
const darkBlue = '#24A1DA'
const red = '#D30E55'
const white = '#fff'

const theme = createTheme({
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#D3D8DE', // add a constant for the color.
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'el-gray' },
          style: {
            minWidth: '65px !important',
            backgroundColor: '#EAEFF3',
            color: '#4E5665',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            svg: {
              path: {
                fill: '#4E5665',
              },
            },
          },
        },
        {
          props: { variant: 'el-gray-50' },
          style: {
            minWidth: '65px !important',
            backgroundColor: '#F6F9FC',
            color: '#32B6F3',
            padding: '8px 16px',
          },
        },
        {
          props: { variant: 'el-error' },
          style: {
            background: 'var(--red500)',
            color: white,
            ':hover': {
              background: 'var(--red500)',
            },
          },
        },
        {
          props: { variant: 'el-sizeXS' },
          style: {
            padding: '8px 12px',
            minWidth: '56px !important',
            backgroundColor: 'var(--blue500)',
            color: white,
            ':hover': {
              background: 'var(--blue600)',
              boxShadow: 'var(--button-box-shadow)',
            },
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'el-error' },
          style: {
            backgroundColor: 'var(--red500)',
            color: white,
          },
        },
        {
          props: { variant: 'el-success' },
          style: {
            backgroundColor: 'var(--green300)',
            color: 'var(--grey800)',
            fontWeight: 'var(--font-weight-normal)',
          },
        },
        {
          props: { variant: 'el-grey' },
          style: {
            backgroundColor: 'var(--grey500)',
            color: 'white',
            fontWeight: 'var(--font-weight-normal)',
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { variant: 'el-link' },
          style: {
            color: 'var(--blue500)',
            fontWeight: 'var(--font-weight-medium)',
            lineHeight: 'var(--line-height-medium)',
            outline: 'none',
            textDecoration: 'none',
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'none',
            },
          },
        },
      ],
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: 'el-error' },
          style: {
            '.MuiBadge-badge': {
              backgroundColor: '#D94F41',
              color: white,
              border: '1px solid white',
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#0000008A',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
          color: 'var(--grey700)',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ececf2',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ececf2',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ececf2',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ececf2',
          },
          '& input::placeholder': {
            fontSize: '0.8rem',
          },
        },
      },
    },
    MuiIcon: {
      variants: [
        {
          props: { color: 'el-success' },
          style: {
            color: 'var(--green500)',
          },
        },
        {
          props: { color: 'el-error' },
          style: {
            color: 'var(--red400)',
          },
        },
        {
          props: { color: 'el-error-bright' },
          style: {
            color: 'var(--red500)',
          },
        },
        {
          props: { color: 'el-grey' },
          style: {
            color: 'var(--grey500)',
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paper': {
            width: '80%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '80%',
            margin: 0,
            position: 'fixed',
            top: 0,
            right: 0,
            boxShadow: 'none',
            overflow: 'hidden',

            '.MuiDialogContent-root': {
              padding: '24px 24px',

              '.directScanSubHeaderStart': {
                justifyContent: 'flex-start',
                gap: '24px',
              },
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    'el-description': {
      color: 'var(--grey200)',
      fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: whiteBlue,
      dark: darkBlue,
      contrastText: white,
    },
    secondary: {
      main: black,
    },
    error: {
      main: red,
    },
    grey: {
      50: 'var(--grey50)',
      200: 'var(--grey200)',
      500: 'var(--grey500)',
    },
    blue: {
      50: 'var(--blue50)',
      500: 'var(--blue500)',
    },
    yellow: {
      500: 'var(--yellow500)',
      600: 'var(--yellow600)',
    },
    red: {
      50: 'var(--red50)',
      400: 'var(--red400)',
      500: 'var(--red500)',
      900: 'var(--red900)',
    },
    green: {
      50: 'var(--green50)',
      400: 'var(--green400)',
      500: 'var(--green500)',
    },
  },
})

export default theme
