import StrHelper from '@lib/helpers/str.helper'
import PagePathConstant from '@lib/constants/page-path.constant'
import { ParsedUrlQuery } from 'querystring'
import { FS_CHECK_REPORT_ID_QUERY_KEY } from '@lib/constants'
import { Nullable } from '@lib/engine-types'

abstract class PageHelper {
  public static buildUrl(path: string, id?: string | number): string {
    if (!id) {
      return path
    }
    return path.replace('[id]', StrHelper.base64Encode(String(id)))
  }

  public static buildOnboardingParamUrl(page: string) {
    return (page += StrHelper.isSubstring(page, '?')
      ? '&onboarding=1'
      : '?onboarding=1')
  }

  public static isOnboardingPrevious(routerQuery: ParsedUrlQuery): boolean {
    return routerQuery?.onboarding !== undefined
  }

  public static buildItemUrl(itemId: string, itemKind: string): string {
    return `${PagePathConstant.DASHBOARD}/${itemKind}/${StrHelper.base64Encode(
      itemId
    )}`
  }

  public static buildAssetPageUrl(assetId: string): string {
    return PagePathConstant.ASSET.replace(
      '[id]',
      StrHelper.base64Encode(assetId)
    )
  }

  public static buildRestoreRpUrl(instancePath: string, rpId: string): string {
    return `${
      PagePathConstant.DASHBOARD
    }/${instancePath}/${StrHelper.base64Encode(rpId)}`
  }

  public static buildMountRpUrl(
    assetKind: any,
    assetId: string,
    recoveryPointId: string
  ) {
    const assetIdEnc = StrHelper.base64Encode(assetId)
    const recoveryPointIdEnc = StrHelper.base64Encode(recoveryPointId)
    return `${PagePathConstant.MOUNT_RECOVERY_POINT}/${assetKind.pathSlug}/${assetIdEnc}/${recoveryPointIdEnc}`
  }

  public static buildFsCheckScanReportDetailsUrl(
    fsCheckScanId: Nullable<string>
  ) {
    if (!fsCheckScanId) {
      return ''
    }

    const buildReportUrl = PageHelper.buildUrl(
      PagePathConstant.REPORT_DETAILS_FS_CHECK
    )
    const reportIdEnc = StrHelper.base64Encode(fsCheckScanId)
    const urlParams = `${FS_CHECK_REPORT_ID_QUERY_KEY}=${reportIdEnc}`

    return `${buildReportUrl}?${urlParams}`
  }
}

export default PageHelper
