import React, { useMemo } from 'react'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import { DateOrTimestamp, FormattedDateTime } from './types'
import { defaultDateTimeFormatter } from './default-date-time-formatter'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings, UTC_TIME_ZONE } from '@lib/constants'
import { getFontSizes, Direction } from './utilities'
import { DateTypography } from './styles'
import { UTC_TIMEZONE_SUFFIX, ROW_LAYOUT, NON_BREAKING_SPACE } from './consts'
import { TimeZoneOption } from '@lib/constants/data/time/types'

interface Props extends Omit<TooltipProps, 'title' | 'children'> {
  date: DateOrTimestamp
  formatFunction?: (
    date: DateOrTimestamp,
    tz: TimeZoneOption
  ) => FormattedDateTime
  direction?: Direction
  children?: React.ReactNode
}

function DateTimeTooltip({
  date,
  placement = 'top-start',
  direction = 'column',
  children,
  formatFunction = defaultDateTimeFormatter,
  ...tooltipPropsRest
}: Props) {
  const tz =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()

  const { date: bodyDate, time: bodyTime } = useMemo(
    () => formatFunction(date, tz),
    [date, tz, formatFunction]
  )

  const { date: titleDate, time: titleTime } = useMemo(
    () => formatFunction(date, UTC_TIME_ZONE()),
    [date, formatFunction]
  )

  const { dateSize, timeSize } = useMemo(
    () => getFontSizes(direction),
    [direction]
  )
  const title = `${titleDate} ${titleTime} ${UTC_TIMEZONE_SUFFIX}`
  // Determines the space between date and time based on the layout direction.
  // Adds a non-breaking space for 'column' layout to keep date and time on the same line,
  // and no space for 'row' layout, placing date and time on separate lines.
  const conditionalSpace = direction === ROW_LAYOUT ? NON_BREAKING_SPACE : ''
  const alignment = direction === ROW_LAYOUT ? 'center' : 'flex-start'
  return (
    <Tooltip title={title} placement={placement} {...tooltipPropsRest}>
      <Stack
        className="date-time-tooltip-stack"
        direction={direction}
        display="inline-flex"
        alignItems={alignment}
      >
        <DateTypography fontSize={dateSize}>{bodyDate}</DateTypography>
        {conditionalSpace}
        <DateTypography fontSize={timeSize}>{bodyTime}</DateTypography>
        {children}
      </Stack>
    </Tooltip>
  )
}

export default DateTimeTooltip
