import {
  UserProfile,
  UserConfig,
  UsersPromiseClient,
} from '@lib/clients/grpc-imports'
import { GrpcClient } from '@lib/clients/grpc-client'
import UserModel from '@lib/models/user.model'
import UserFactory from '@lib/factories/user.factory'

export class UsersClient extends GrpcClient<UsersPromiseClient> {
  #usersPromiseClient: UsersPromiseClient

  #CLIENT_TYPE_ID = 'UsersClient'

  constructor(hostName = '') {
    super()
    this.#usersPromiseClient = this.getClient(hostName)
  }

  async getUserProfile(): Promise<UserModel> {
    const request = new UserProfile()

    const response: UserProfile = await this.retryGrpcCall(
      () => this.#usersPromiseClient.getUserProfile(request, this.metadata()),
      {
        requestName: 'UsersPromiseClient/getUserProfile',
      }
    )

    return UserFactory.buildFromGRPC(response.toObject())
  }

  async getUserConfig(): Promise<any> {
    const response = await this.retryGrpcCall(
      () =>
        this.#usersPromiseClient.getUserConfig(
          new UserConfig(),
          this.metadata()
        ),
      {
        requestName: 'UsersPromiseClient/getUserConfig',
      }
    )

    return JSON.parse(response.getJson())
  }

  protected innerClientTypeId(): string {
    return this.#CLIENT_TYPE_ID
  }

  protected initClient(hostName = ''): UsersPromiseClient {
    return new UsersPromiseClient(hostName, null, null)
  }
}
