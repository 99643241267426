import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ToastHelper from '@lib/helpers/toast.helper'
import ActionInterface from '@lib/interfaces/action.interface'
import GrpcNotificationsReportsService from '@lib/services/grpc/notifications/grpc-reports.service'
import {
  REQUEST_BACKUP_AUDIT,
  REQUEST_COMPLIANCE_SUMMARY,
  REQUEST_CREATE_REPORT_SCHEDULE,
  REQUEST_GENERATED_REPORTS,
  REQUEST_UPDATE_REPORT_SCHEDULE,
  REQUEST_VOLUME_VULNERABILITIES_GENERATED_REPORTS,
  requestGeneratedReports,
  setBackupAuditReport,
  setComplianceSummaryReport,
  setGeneratedReports,
  setVolumeVulnerabilitiesReports,
} from '@store/actions/reports.action'

import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
  sentrySimpleReThrowHandler,
} from './epic-func'
import TypedActionInterface from '@lib/interfaces/typed-action.interface'
import { ReportScheduleRequestInterface } from '@lib/interfaces/schedule-report.interface'
import { emptyAction } from '@store/actions/default/empty.action'
import GrpcReportSchedulesService from '@lib/services/grpc/notifications/grps-schedule-reports.service'
import ReportScheduleModel from '@lib/models/report-schedule.model'

export const requestComplianceSummaryEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_COMPLIANCE_SUMMARY],
      PreloaderConstants.REQUEST_COMPLIANCE_SUMMARY,
      (action: ActionInterface) => {
        return GrpcNotificationsReportsService.getComplianceSummary(
          action.payload
        ).catch((error: Error) => {
          ToastHelper.error('Something went wrong: ' + error?.message)
          return sentryReThrowCatchHandler('Cannot get compliance summary')
        })
      },
      (data: string) => {
        /* eslint-disable @typescript-eslint/naming-convention*/
        const {
          policy_managed_instance_backups = [],
          policy_managed_volume_backups = [],
          customer_managed_file_backups = [],
          customer_managed_block_backups = [],
          customer_managed_stream_backups = [],
          customer_managed_instance_backups = [],
          customer_managed_volume_backups = [],
        } = JSON.parse(data)
        /* eslint-enable @typescript-eslint/naming-convention*/

        return setComplianceSummaryReport({
          policy_managed_instance_backups,
          policy_managed_volume_backups,
          customer_managed_file_backups,
          customer_managed_block_backups,
          customer_managed_stream_backups,
          customer_managed_instance_backups,
          customer_managed_volume_backups,
        })
      },
      ErrorGroupConstants.COMPLIANCE_SUMMARY
    )
  )

export const requestBackupAuditEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_BACKUP_AUDIT],
      PreloaderConstants.REQUEST_BACKUP_AUDIT,
      (action: ActionInterface) => {
        return GrpcNotificationsReportsService.getBackupAudit(
          action.payload
        ).catch((error: Error) => {
          ToastHelper.error('Something went wrong: ' + error?.message)
          return sentryReThrowCatchHandler('Cannot get backup audit')
        })
      },
      (data: string) => {
        return setBackupAuditReport(JSON.parse(data))
      },
      ErrorGroupConstants.BACKUP_AUDIT
    )
  )

export const listReportSchedulesEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_GENERATED_REPORTS],
      PreloaderConstants.REQUEST_SCHEDULE_REPORTS_LIST,
      () => {
        return GrpcReportSchedulesService.listReportSchedulesRequest().catch(
          (error: Error) => {
            return sentrySimpleReThrowHandler(error)
          }
        )
      },
      (data: Array<ReportScheduleModel>) => {
        return setGeneratedReports(data)
      },
      ErrorGroupConstants.SCHEDULE_REPORT
    )
  )

export const volumeVulnerabilitiesReportsEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_VOLUME_VULNERABILITIES_GENERATED_REPORTS],
      PreloaderConstants.REQUEST_VOLUME_VULNERABILITIES_REPORTS_LIST,
      (action: ActionInterface) => {
        return GrpcNotificationsReportsService.getListReportsByKind(
          action.payload
        ).catch((error: Error) => {
          return sentrySimpleReThrowHandler(error)
        })
      },
      (data: Array<ReportScheduleModel>) => {
        return setVolumeVulnerabilitiesReports(data)
      },
      ErrorGroupConstants.VOLUME_VULNERABILITIES_REPORT
    )
  )

export const createReportScheduleEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_CREATE_REPORT_SCHEDULE],
      PreloaderConstants.REQUEST_CREATE_REPORT_SCHEDULE,
      (action: TypedActionInterface<ReportScheduleRequestInterface>) => {
        return GrpcReportSchedulesService.createReportScheduleRequest(
          action.payload
        ).catch((error: Error) => {
          return sentrySimpleReThrowHandler(error)
        })
      },
      () => {
        ToastHelper.success('Report schedule created successfully')
        return emptyAction()
      },
      ErrorGroupConstants.SCHEDULE_REPORT
    )
  )

export const updateReportScheduleEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_UPDATE_REPORT_SCHEDULE],
      PreloaderConstants.REQUEST_UPDATE_REPORT_SCHEDULE,
      (action: TypedActionInterface<ReportScheduleRequestInterface>) => {
        return GrpcReportSchedulesService.updateReportScheduleRequest(
          action.payload
        ).catch((error: Error) => {
          return sentrySimpleReThrowHandler(error)
        })
      },
      () => {
        ToastHelper.success('Report schedule updated successfully')
        return requestGeneratedReports()
      },
      ErrorGroupConstants.SCHEDULE_REPORT
    )
  )
