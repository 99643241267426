import { DateRangeTuple } from '@lib/engine-types'
import { GeneratedReportInterface } from '@lib/interfaces/generated-report.interface'
import GrpcBaseService from '@lib/services/grpc/base/grpc-base.service'
import { ReportKind } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/report_schedule_pb'
import { ReportsPromiseClient } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/reports_grpc_web_pb'
import {
  GetBackupAuditRequest,
  GetComplianceSummaryRequest,
  ListReportsRequest,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/reports_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

class GrpcNotificationsReportsService extends GrpcBaseService {
  protected static client = new ReportsPromiseClient('', null, null)
  protected static clientName = 'Compliance'

  public static async getComplianceSummary([
    startDate,
    endDate,
  ]: DateRangeTuple): Promise<string | undefined> {
    const request = new GetComplianceSummaryRequest()
    request.setStartTime(Timestamp.fromDate(new Date(Number(startDate))))
    request.setEndTime(Timestamp.fromDate(new Date(Number(endDate))))

    const result = await this.handleQuery(
      this.client.getComplianceSummary,
      request
    )
    return result.toObject()?.complianceSummaryJson
  }

  public static async getBackupAudit([
    startDate,
    endDate,
  ]: DateRangeTuple): Promise<string | undefined> {
    const request = new GetBackupAuditRequest()
    request.setStartTime(Timestamp.fromDate(new Date(Number(startDate))))
    request.setEndTime(Timestamp.fromDate(new Date(Number(endDate))))

    const result = await this.handleQuery(this.client.getBackupAudit, request)
    return result.toObject()?.backupAuditJson
  }

  public static async getListReports({
    reportKindsList,
    dateRange,
    pageSize,
    token,
  }: {
    reportKindsList: Array<ReportKind>
    dateRange: DateRangeTuple
    pageSize: number
    token?: string
  }): Promise<Array<GeneratedReportInterface> | undefined> {
    const [startDate, endDate]: DateRangeTuple = dateRange
    const request = new ListReportsRequest()

    request.setPageSize(pageSize)
    request.setStartTime(Timestamp.fromDate(new Date(Number(startDate))))
    request.setEndTime(Timestamp.fromDate(new Date(Number(endDate))))
    request.setKindsList(reportKindsList)

    if (token) {
      request.setPageToken(token)
    }

    const result = await this.handleQuery(this.client.listReports, request)
    return result.toObject()?.reportsList
  }

  public static async getListReportsByKind({
    reportKind,
    dateRange,
    pageSize,
    token,
  }: {
    reportKind: ReportKind
    dateRange: DateRangeTuple
    pageSize: number
    token?: string
  }): Promise<Array<GeneratedReportInterface> | undefined> {
    const [startDate, endDate]: DateRangeTuple = dateRange
    const request = new ListReportsRequest()

    request.setPageSize(pageSize)
    request.setStartTime(Timestamp.fromDate(new Date(Number(startDate))))
    request.setEndTime(Timestamp.fromDate(new Date(Number(endDate))))
    request.setKindsList([reportKind])

    if (token) {
      request.setPageToken(token)
    }

    const result = await this.handleQuery(this.client.listReports, request)
    return result.toObject()?.reportsList
  }
}

export default GrpcNotificationsReportsService
