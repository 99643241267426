import ValueInterface from '@lib/interfaces/value.interface'

export const REPORT_DOWNLOAD_FORMATS: Array<ValueInterface> = [
  {
    name: 'PDF',
    value: 'pdf',
  },
  {
    name: 'XLSX',
    value: 'xlsx',
  },
  {
    name: 'DOCX',
    value: 'docx',
  },
  {
    name: 'CSV',
    value: 'csv',
  },
]

// Took kinds from blue-stack libs
export enum ReportKind {
  VolumeVulnerabilities = 0,
  InstanceVulnerabilities = 1,
  SnapshotVulnerabilities = 2,
}

export enum GeneratedReportKind {
  VOLUMEVULNERABILITIES = 0,
  INSTANCEVULNERABILITIES = 1,
  SNAPSHOTVULNERABILITIES = 2,
}

//

export enum Format {
  XLSX = 0,
  DOCX = 1,
  CSV = 2,
  PDF = 3,
}

export enum DayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum FrequencyCase {
  FREQUENCY_NOT_SET = 0,
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}
