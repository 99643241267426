"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFS = void 0;
const aws_asset_1 = require("../../../assets/aws/aws-asset");
class EFS extends aws_asset_1.AWSAsset {
    #ccAssetId;
    #state;
    constructor(parameters) {
        const { ccAssetId, state, ...awsAssetParameters } = parameters;
        super(awsAssetParameters);
        this.#ccAssetId = ccAssetId;
        this.#state = state;
    }
    get ccAssetId() {
        return this.#ccAssetId;
    }
    get state() {
        return this.#state;
    }
    get canBeDirectlyScanned() {
        return true;
    }
}
exports.EFS = EFS;
