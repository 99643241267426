"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EC2 = void 0;
const aws_asset_1 = require("../../../assets/aws/aws-asset");
// TODO Add a Method for Creating a New Instance with Updated ebsIds
class EC2 extends aws_asset_1.AWSAsset {
    #state;
    #type;
    #amiDetails;
    #ccAssetId;
    #retentionPolicy;
    #ebsIds;
    #osKind;
    #awsVpcId;
    #startedAt;
    #hasReplicatedBackups;
    constructor(parameters) {
        const { state, type, amiDetails, ccAssetId, retentionPolicy, ebsIds, osKind, awsVpcId, startedAt, hasReplicatedBackups, ...awsAssetParameters } = parameters;
        super(awsAssetParameters);
        this.#state = state;
        this.#type = type;
        this.#ebsIds = Object.freeze(ebsIds);
        this.#amiDetails = amiDetails;
        this.#osKind = osKind;
        this.#retentionPolicy = Object.freeze(retentionPolicy);
        this.#ccAssetId = ccAssetId;
        this.#awsVpcId = awsVpcId;
        this.#startedAt = startedAt;
        this.#hasReplicatedBackups = hasReplicatedBackups;
    }
    get state() {
        return this.#state;
    }
    get type() {
        return this.#type;
    }
    get amiDetails() {
        return this.#amiDetails;
    }
    get ccAssetId() {
        return this.#ccAssetId;
    }
    get retentionPolicy() {
        return [...this.#retentionPolicy];
    }
    get ebsIds() {
        return this.#ebsIds;
    }
    set ebsIds(newEbsIds) {
        this.#ebsIds = Object.freeze([...newEbsIds]);
    }
    get osKind() {
        return this.#osKind;
    }
    get awsVpcId() {
        return this.#awsVpcId;
    }
    get startedAt() {
        return this.#startedAt;
    }
    get canBeDirectlyScanned() {
        return true;
    }
    get hasReplicatedBackups() {
        return this.#hasReplicatedBackups;
    }
}
exports.EC2 = EC2;
