import { Format, ReportKind } from '@lib/constants/report-formats.constant'
import { ScheduleItem } from '@lib/interfaces/schedule-report.interface'
import ReportScheduleModel from '@lib/models/report-schedule.model'
import { ReportSchedule } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/report_schedule_pb'

abstract class ReportScheduleFactory {
  public static buildReportScheduleFromGrpc(
    params: ReportSchedule.AsObject
  ): ReportScheduleModel {
    return new ReportScheduleModel({
      id: params.id,
      reportKind: params.kind as unknown as ReportKind,
      scheduleItemsList: params.scheduleItemsList as Array<ScheduleItem>,
      emailsList: params.emailsList,
      reportFormatsList: params.reportFormatsList,
    })
  }

  public static buildReportScheduleFake(): ReportScheduleModel {
    return new ReportScheduleModel({
      id: '1',
      reportKind: ReportKind.VolumeVulnerabilities,
      scheduleItemsList: [
        {
          id: '1',
          schedule: {
            weekly: {
              day: 4,
            },
          },
        },
      ],
      emailsList: ['email@gmail.com'],
      reportFormatsList: [Format.CSV, Format.XLSX],
    })
  }
}

export default ReportScheduleFactory
