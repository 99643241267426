import React from 'react'
import { CellWithDateTimeTooltipOwnProps } from './types'
import {
  DateTimeTooltip,
  DateOrTimestamp,
} from '@components-simple/date-time-tooltip'

function CellWithDateTimeTooltip({
  date,
  direction,
}: CellWithDateTimeTooltipOwnProps) {
  if (!date) {
    return null
  }

  return (
    <DateTimeTooltip
      placement="top-start"
      direction={direction}
      date={date as DateOrTimestamp}
    />
  )
}

export default CellWithDateTimeTooltip
