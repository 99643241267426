import ReportPeriodInterface from '@lib/interfaces/report-period.interface'
import * as actions from '@store/actions/action-types'
import ValueInterface from '@lib/interfaces/value.interface'
import { ListScansByFiltersReqFilterParams } from '@lib/clients/scans/types'

export function setFsCheckReportsPeriod(
  payload: ReportPeriodInterface
): actions.SetFsCheckReportsPeriodAction {
  return {
    type: actions.SET_FS_CHECK_REPORTS_PERIOD,
    payload,
  }
}

export function setFsCheckReportsSelectedFilters(
  payload: Array<ValueInterface>
): actions.SetFsCheckReportsSelectedFiltersAction {
  return {
    type: actions.SET_FS_CHECK_REPORTS_SELECTED_FILTERS,
    payload,
  }
}

export function setFsCheckReportsSelectedRpFilters(
  payload: string
): actions.SetFsCheckReportsSelectedRpFiltersAction {
  return {
    type: actions.SET_FS_CHECK_REPORTS_SELECTED_RP_FILTERS,
    payload,
  }
}

export function setFsCheckReportsSelectedFiltersForApi(
  payload: ListScansByFiltersReqFilterParams
): actions.SetFsCheckReportsSelectedFiltersForApiAction {
  return {
    type: actions.SET_FS_CHECK_REPORTS_SELECTED_FILTERS_FOR_API,
    payload,
  }
}
