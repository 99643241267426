"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericHost = void 0;
const asset_1 = require("../asset");
class GenericHost extends asset_1.Asset {
    #hostname;
    #cpuCoresCount;
    #osName;
    #retentionPolicy;
    constructor(parameters) {
        const { hostname, cpuCoresCount, osName, retentionPolicy, ...assetParameters } = parameters;
        super(assetParameters);
        this.#hostname = hostname;
        this.#cpuCoresCount = cpuCoresCount;
        this.#osName = osName;
        this.#retentionPolicy = Object.freeze([...retentionPolicy]);
    }
    get hostname() {
        return this.#hostname;
    }
    get cpuCoresCount() {
        return this.#cpuCoresCount;
    }
    get osName() {
        return this.#osName;
    }
    get retentionPolicy() {
        return [...this.#retentionPolicy];
    }
    get canBeDirectlyScanned() {
        return false;
    }
}
exports.GenericHost = GenericHost;
