import useCurrentView from './useCurrentView'

export default function useManagementView() {
  const view = useCurrentView()

  const isOverviewView = view.variant === 'overview'

  const isSecurityView = view.variant === 'security'

  const isRecoveryView = view.variant === 'recovery'

  return {
    view,
    isOverviewView,
    isSecurityView,
    isRecoveryView,
  }
}
