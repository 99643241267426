import { ReportSchedulesPromiseClient } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/reports_grpc_web_pb'
import GrpcBaseService from '../base/grpc-base.service'
import { ReportScheduleRequestInterface } from '@lib/interfaces/schedule-report.interface'
import {
  CreateReportScheduleRequest,
  ListReportSchedulesRequest,
  UpdateReportScheduleRequest,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/reports_pb'
import {
  ReportKind,
  ReportSchedule,
  Schedule,
  ScheduleItem,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/report_schedule_pb'
import ReportScheduleFactory from '@lib/factories/report-schedule.factory'

class GrpcReportSchedulesService extends GrpcBaseService {
  protected static client = new ReportSchedulesPromiseClient('', null, null)
  protected static clientName = 'Schedules'

  /**
   * Create report schedule
   * @memberof GrpcReportSchedulesService
   */
  public static async createReportScheduleRequest(
    data: ReportScheduleRequestInterface
  ) {
    const request = new CreateReportScheduleRequest()

    const scheduleItem = new ScheduleItem()

    const schedule = new Schedule()

    if (!data.scheduleItemsList[0]?.schedule) {
      return
    }

    if (
      data.scheduleItemsList &&
      'daily' in data.scheduleItemsList[0].schedule
    ) {
      schedule.setDaily(new Schedule.Daily())
    }

    if (
      'weekly' in data.scheduleItemsList[0].schedule &&
      data.scheduleItemsList[0].schedule.weekly
    ) {
      const day = new Schedule.Weekly()
      day.setDay(data.scheduleItemsList[0].schedule.weekly.day)
      schedule.setWeekly(day)
    }

    if (
      'monthly' in data.scheduleItemsList[0].schedule &&
      data.scheduleItemsList[0].schedule.monthly
    ) {
      const day = new Schedule.Monthly()
      day.setDayOfMonth(data.scheduleItemsList[0].schedule.monthly.dayOfMonth)
      schedule.setMonthly(day)
    }

    scheduleItem.setId('')

    scheduleItem.setSchedule(schedule)

    request.setReportKind(data.reportKind as unknown as ReportKind)

    request.setScheduleItemsList([scheduleItem])

    request.setEmailsList(data.emailsList)

    request.setReportFormatsList(data.reportFormatsList)

    const result = await this.handleQuery(
      this.client.createReportSchedule,
      request
    )

    return result.toObject()
  }

  /**
   * Update report schedule
   * @memberof GrpcReportSchedulesService
   */

  public static async updateReportScheduleRequest(
    data: ReportScheduleRequestInterface
  ) {
    const request = new UpdateReportScheduleRequest()

    const reportSchedule = new ReportSchedule()

    if (data.id) {
      reportSchedule.setId(data.id)
    }

    reportSchedule.setEmailsList(data.emailsList)

    reportSchedule.setReportFormatsList(data.reportFormatsList)

    const scheduleItem = new ScheduleItem()

    reportSchedule.setScheduleItemsList([scheduleItem])

    scheduleItem.setId(data.scheduleItemsList?.[0]?.id ?? '')

    const schedule = new Schedule()

    if (!data.scheduleItemsList[0]?.schedule) {
      return
    }

    if ('daily' in data.scheduleItemsList[0].schedule) {
      schedule.setDaily(new Schedule.Daily())
    }

    if (
      'weekly' in data.scheduleItemsList[0].schedule &&
      data.scheduleItemsList[0].schedule.weekly
    ) {
      const day = new Schedule.Weekly()
      day.setDay(data.scheduleItemsList[0].schedule.weekly.day)
      schedule.setWeekly(day)
    }

    if (
      'monthly' in data.scheduleItemsList[0].schedule &&
      data.scheduleItemsList[0].schedule.monthly
    ) {
      const day = new Schedule.Monthly()
      day.setDayOfMonth(data.scheduleItemsList[0].schedule.monthly.dayOfMonth)
      schedule.setMonthly(day)
    }

    scheduleItem.setSchedule(schedule)

    request.setReportSchedule(reportSchedule)

    const result = await this.handleQuery(
      this.client.updateReportSchedule,
      request
    )

    return result.toObject()
  }

  /**
   * @memberof GrpcReportSchedulesService
   *
   */
  public static async listReportSchedulesRequest() {
    const request = new ListReportSchedulesRequest()

    const result = await this.handleQuery(
      this.client.listReportSchedules,
      request
    )

    return result
      .toObject()
      ?.reportSchedulesList.map((report) =>
        ReportScheduleFactory.buildReportScheduleFromGrpc(report)
      )
  }
}

export default GrpcReportSchedulesService
