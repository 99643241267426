// index = 0 - simple block
// index = 1 - buttons block
import ValueInterface from '@lib/interfaces/value.interface'

export enum SnapshotVulnerabilitiesTypeName {
  UNENCRYPTED = 'Unencrypted',
  PUBLIC_PERMISSIONS = 'w. Public Permissions',
  OLDER_THAN_30_DAYS = 'Older than 30 days',
}

export enum DashboardColors {
  BLUE = 'blue500',
  RED = 'red600',
}

export enum EbsInventoryStatus {
  ATTACHED = 'Attached',
  UNATTACHED = 'Unattached',
}

export const DASHBOARD_GRID_MENU = (): Array<ValueInterface> => [
  {
    type: 0,
    label: 'Protection Summary',
    name: 'protection',
    options: [
      {
        label: 'Protection Summary',
        name: 'protection_summary',
      },
      {
        label: 'Total assets (AWS, VNware)',
        name: 'total_assets',
      },
      {
        label: 'AWS',
        name: 'aws',
      },
      {
        label: 'AWS regions',
        name: 'aws_regions',
      },
    ],
  },
  {
    type: 0,
    label: 'Backups',
    name: 'backups',
    options: [
      {
        label: 'Some Option 1',
        name: 'some_option_1',
      },
      {
        label: 'Some Option 2',
        name: 'some_option_2',
      },
      {
        label: 'Some Option 3',
        name: 'some_option_3',
      },
    ],
  },
  {
    type: 0,
    label: 'Alerts',
    name: 'alerts',
  },
  {
    type: 0,
    label: 'Storage',
    name: 'storage',
    options: [
      {
        label: 'some storage',
        name: 'some storage 1',
      },
    ],
  },
  {
    type: 1,
    label: 'Active Buttons',
    name: 'active_buttons',
    options: [
      {
        label: 'Protect assets',
        name: 'protect_assets',
      },
      {
        label: 'Restore backup',
        name: 'restore_backup',
      },
      {
        label: 'Manage Policy',
        name: 'manage_name',
      },
      {
        label: 'Add source',
        name: 'add_source',
      },
    ],
  },
]
export const DASHBOARD_GRID_DEFAULTS = () => ({
  // for each element
  enabled: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  sizes: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
  // for each menu
  sort: [0, 1, 2, 3, 4],
})
